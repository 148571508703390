import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dumbbell Jumping Lunges 5×2/leg`}</p>
    <p>{`Dumbbell Stiff Leg Deadlifts 5×4`}</p>
    <p>{`then,`}</p>
    <p>{`2 Rounds for time:`}</p>
    <p>{`10-Box Jumps (30/24″)`}</p>
    <p>{`20-Back Squats (185/125, bar starts on the floor)`}</p>
    <p>{`30-Burpees`}</p>
    <p>{`40-K2E’s`}</p>
    <p>{`50ft Shuttle Run x 10`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      